// @flow
/* eslint import/no-webpack-loader-syntax: 0 */
import React, { Component } from "react";
import Spinner from "./loader.js";
import { Resizable } from 'react-resizable';
import { Link } from "react-router-dom";
import Select from 'react-select';
import MaskedInput from "react-text-mask";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore
} from "react-toasts";
import { API_ENDPOINT, callApi } from "../../utils/api";
import AddSro from "../sro/AddSro";
import './ReactResizable.css';
import ScrutinyScheduleItem from "./ScrutinyScheduleItem";
import { isEmpty } from 'lodash';

import PDFHighlighter from '../PDF-Highlighter';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import CountdownTimer from "../file/CountdownTimer";

interface Executant {
  executant: string;
  executant_id?: string;
}
interface LegalHiers {
  legal_hiers: string;
  relationship_dropdown: string;
  name_of_demise: string;
  legal_hiers_id?: string;
}
interface Favour {
  favour: string;
  favour_id?: string;
}
interface AllProps {
  match: any;
  history: any;
  location: any;
}

export default class ScrutinyForm extends Component<AllProps> {
  state = {
    timer: 0,
    required: false,
    loader: true,
    base: "",
    pageNumber: 1,
    numPages: 0,
    fod_fk: "",
    date: "",
    doc_no: "",
    type: "",
    service_no: "",
    nature_doc: "",
    old_surevey: "",
    current_survey: "",
    extent: "",
    mother_doc: "",
    org_pres: "",
    remark: "",
    main_executant: [] as Executant[],
    main_favour: [],
    deeds: [],
    deed: [],
    fileid: "",
    delete_items: {
      main_favour: [],
      main_executant: [],
      legalHiersFields: []
    },
    originalPresents: [],
    isIssue: "false",
    issued_by: "",
    templates: [],
    template: "",
    dynamicFields: [],
    optionList: [],
    legalHiersFields: [
      { legal_hiers: "", relationship_dropdown: "", name_of_demise: "" }
    ],
    legalHiers: false,
    categories: [],
    isSchedule: "false",
    schedule_deed: [],
    registers: [],
    reg: "",
    sroList: [],
    approve: "",
    temp: "",
    scrutiny_id: "",
    obtined: "",
    unit: "",
    isLoading: false,
    documentWidth: 50, documentHeight: 600,
    formWidth: 45, formHeight: 835,
    total_extent: "",
    extent_unit: "",
    is_total_extent: "",
    is_total_extent_total: false,
    is_total_extent_part: false,
    selectedDoc: "",
    allDocuments: [],
    modifySelectToCommas: [],
    staticFilesToHide: [''],
    note: '',
    extentUnits: [],
    inactive: 0,
    selectedSro: {
      id: 0,
      sro_id: 0,
      sro: ""
    },
    url: '',
    highlights: [],
    rotation: 0,
    disabled: true,
    enableWheel: false,
    panningEnabled: false,
    transformEnabled: false,
    pinchEnabled: false,
    enableTouchPadPinch: false,
    velocityEqualToMove: false,
    enableVelocity: false,
    limitsOnWheel: true,
    isHightlightsLoaded: false,
    docToPreview: false,
    sheetCount: "",
    totalSheets: "",
    missingSheets: "",
    autoAssign: [
      {
        "id": "1",
        "module_name": "Auto Assign",
        "status": true
      }
    ],
    //selectedTemplate: null,
    isTemplateSchedule: false,
    scrutyCheck: false
  };
  document = {
    base: "",
    numPages: 0,
    pageNumber: 1
  };
  countdownTimerRef: CountdownTimer | null = null;
  componentDidMount() {
    this.getDeeds();
    this.getCategories();
    this.getRegisters();
    this.getRevenueDocs();
    this.getOriginalPresents();
    this.getScrutinizedDocs();
    this.getSroList();
    this.getScrutinyStatus();
    this.getAllDocuments();
    this.getExtentUnits();
    this.getScrutinizedDocs();
    this.liveinsert();
    // this.getAutoAssignStatus();
    setInterval(() => {
      this.incrementTimer();
    }, 1000);
  }
  incrementTimer = () => {
    const { timer } = this.state;
    this.setState({ timer: timer + 1 })
  }
  getAutoAssignStatus = () => {
    callApi("GET", "module_option_select.php", "")
      .then((res) => res.data)
      .then((result) => {
        this.setState({ autoAssign: result.responseJson.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  liveinsert = () => {

    const user: any = sessionStorage.getItem("user");
    const role_id = user ? JSON.parse(user)["team_type"] : "";
    const userId = user ? JSON.parse(user)["userId"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    console.log("214", role);
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const bankName: any = localStorage.getItem("bankName");
    const fileNo: any = localStorage.getItem("filenumber");
    const in_reason: any = localStorage.getItem("inreasonlive");
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    const textRay = `Currently working on File_No : ${this.props.match.params.fileid} On All Documents Doc.No :${this.props.match.params.id}`;
    let obj = {
      file_no: fileNo,
      in_time: curentTime,
      reason: in_reason,
      user_id: userId,
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: textRay,
      bank_name: bankName,
    }
    console.log("231", obj);
    callApi("POST", "live_track_insert.php", obj)
      .then(res => res.data)
      .then(response => {

      })
  }
  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({ rotation: newRotation })
  }

  onDocumentResize = (event, { element, size, handle }) => {
    if (size.width <= 60) {
      this.setState({ documentWidth: size.width, documentHeight: size.height, formWidth: 90 - size.width });
    }
  };
  onFormResize = (event, { element, size, handle }) => {
    this.setState({ formWidth: size.width, formHeight: size.height, documentWidth: 90 - size.width });
  };
  toggleDocStatus = () => {
    const inactive = this.state.inactive ? 0 : 1;
    callApi("POST", "doc_status_update.php", { id: this.props.match.params.id, inactive, flag: 'scruty' })
      .then(res => res.data)
      .then(response => {
        sessionStorage.removeItem('overviewDocument');
        this.setState({ inactive })
      })
      .catch(err => {
        console.log(err);
      });
  };
  getCategories = () => {
    callApi("POST", "category.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ categories: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  getRegisters = () => {
    callApi("POST", "reg_deed.php")
      .then(res => res.data)
      .then(response => {
        if (response && response.data && response.data.length) {
          const registers = response.data.map((opt: any) => ({ name: opt, id: opt }));
          this.setState({ registers });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getScrutinizedDocs = () => {
    callApi("POST", "doc_scrutinize_get.php", {
      id: this.props.match.params.id
    })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const state = response.data[0].scrutinize[0];
          // state.base = response.data[0].base[0].base;
          if (state.nature_doc) {
            this.getTemplates(state.nature_doc);
          }
          state.main_favour = response.data[0].favour;
          state.main_executant = response.data[0].executant;
          state.highlights = response.data[0].highlights || [];
          state.sheetCount = response.data[0].scrutinize[0].sheetCount || "";
          state.totalSheets = response.data[0].scrutinize[0].totalSheets || "";
          state.missingSheets = response.data[0].scrutinize[0].missingSheets || "";
          let legalHiersData = response.data[0].legalHiersFields;
          if (legalHiersData && legalHiersData.length > 0) {
            state.legalHiersFields = response.data[0].legalHiersFields.length > 0 ? response.data[0].legalHiersFields : this.state.legalHiersFields;
            state.legalHiers = true;
          }
          if (!state.main_favour || !state.main_favour.length) {
            state.main_favour = [];
            state.main_favour.push({ favour: "" });
          }
          if (!state.main_executant || !state.main_executant.length) {
            state.main_executant = [];
            state.main_executant.push({ executant: "" });
          }
          if (state.template) {
            this.getDynamicFields(state.template);
            this.manageStaticFields(state.template)
          }
          if (state.template == "18" || state.schedule_deed) {
            this.setState({ isSchedule: "true" });
          }
          const deedSelected = state.deed ? state.deed.split(",") : [];
          state.deed = [];
          deedSelected.forEach(deed => {
            const selDeed: any = this.state.deeds.find((all: any) => all.id === deed)
            state.deed.push(selDeed);
          });
          this.setState({ ...state, isHightlightsLoaded: true });
          if (state.is_total_extent === "total") {
            this.setState({ is_total_extent_total: true });
          } else if (state.is_total_extent === "part") {
            this.setState({ is_total_extent_part: true });
          }
          else {
            this.setState({ is_total_extent_total: false });
            this.setState({ is_total_extent_part: false });
          }
          if (state.nature_doc != "") {
            this.findIsIssue(state.nature_doc);
          }
          this.getDeeds();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getRevenueDocs = () => {
    this.setState({ loader: true });
    callApi("POST", "getbaseby_id.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = response.data[0].base[0].base;
          const state = this.state;
          state.type = response.data[0].base[0].type;
          state.selectedDoc = "";
          this.setState({ ...document, state, loader: false });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getExtentUnits = () => {
    const extentUnits = [
      {
        id: 'Sq.Ft',
        name: 'Sq.Ft'
      },
      {
        id: 'Sq.Yards',
        name: 'Sq.Yards'
      },
      {
        id: 'Sq.Meter',
        name: 'Sq.Meter'
      },
      {
        id: 'Sq.Lings',
        name: 'Sq.Lings'
      },
      {
        id: 'Acres',
        name: 'Acres'
      },
      {
        id: 'Cents',
        name: 'Cents'
      },
      {
        id: 'Hectares',
        name: 'Hectares'
      },
      {
        id: 'Ground',
        name: 'Ground'
      },
      {
        id: 'Kuzhi',
        name: 'Kuzhi'
      },
      {
        id: 'Kaani',
        name: 'Kaani'
      },
      {
        id: 'Ares',
        name: 'Ares'
      },
      {
        id: 'Veesam',
        name: 'Veesam'
      },
      {
        id: 'Santhiyar',
        name: 'Santhiyar'
      }
    ]
    this.setState({ extentUnits })
  };
  getDeeds = () => {
    callApi("POST", "deed_master_get.php", { category: this.state.type })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const deeds = response.data;
          this.setState({ deeds });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getScrutinyStatus = () => {
    callApi("POST", "temp_show.php", { id: this.props.match.params.id })
      .then(res => res.data)
      .then(response => {
        if (response.data) {
          const temp = response.data.temp;
          const approve = response.data.approve;
          this.setState({ temp, approve });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getDynamicFields = id => {
    callApi("POST", "input_master_get.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          response.data.map(dynamic => {
            if (dynamic.type === 'select') {
              const patchDynamicField: any = [];
              const fieldSelected = !isEmpty(this.state[dynamic.name]) ? this.state[dynamic.name].split(",") : [];
              fieldSelected.map(val => {
                const selField: any = dynamic.optionList.find((all: any) => all.name === val)
                patchDynamicField.push(selField);
              });
              this.setState({ [dynamic.name]: patchDynamicField, modifySelectToCommas: [dynamic.name] })
            }
          })
          this.setState({ dynamicFields: response.data, legalHiers: response.legal, optionList: response.optionList });
        } else {
          this.setState({ dynamicFields: [], legalHiers: false, optionList: [] });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  manageStaticFields = id => {
    callApi("POST", "dynamic_form.php", { id })
      .then(res => res.data)
      .then(response => {
        this.setState({ staticFilesToHide: response.data });
        console.log("staticFilesToHide", this.state.staticFilesToHide);
      })
      .catch(err => {
        console.log(err);
      });
  };
  getAllDocuments = () => {
    callApi("POST", "all_doc_no_list.php", { id: this.props.match.params.fileid })
      .then(res => res.data)
      .then(response => {
        this.setState({ allDocuments: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  findIsIssue = modal => {
    const selectedNatureDoc: any = this.state.deeds.filter(
      (itm: any) => itm.id === modal)[0];
    this.setState({ isIssue: selectedNatureDoc.issue + "" });
    return selectedNatureDoc.issue;
  };
  findIsSchedule = modal => {
    const selectedNatureDoc: any = this.state.deeds.filter(
      (itm: any) => itm.id === modal.nature_doc
    )[0];
    this.setState({ isSchedule: selectedNatureDoc.schedule + "" });
    return selectedNatureDoc.schedule;
  };
  getOriginalPresents = () => {
    callApi("POST", "original_get.php")
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          // const state = this.state;
          const originalPresents = response.data;
          this.setState({ originalPresents });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  getSroList = () => {
    callApi("POST", "sro_get.php")
      .then(res => res.data)
      .then(response => {
        this.setState({ sroList: response.data });
      })
      .catch(err => {
        console.log(err);
      });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  addSro = () => {
    this.setState({ selectedSro: Object.assign({}) });
  };
  handleChange = (e, propName?, fieldType?) => {
    if (fieldType === 'multiselect') {
      const modifySelectToCommas: any = this.state.modifySelectToCommas;
      if (this.state.modifySelectToCommas && this.state.modifySelectToCommas.length) {
        if (modifySelectToCommas.indexOf(propName) === -1) {
          modifySelectToCommas.push(propName)
        }
      } else {
        modifySelectToCommas.push(propName)
      }
      this.setState({ [propName]: e, modifySelectToCommas })
      return;
    }
    let fieldName = fieldType === 'singleselect' ? propName : e.target.name;
    let fieldValue = fieldType === 'singleselect' ? e.value : e.target.value;
    if (fieldName === "nature_doc") {
      const isIssue = e.issue + '';
      const isSchedule = e.schedule + '';
      this.getTemplates(e.id);
      this.setState({ isIssue, isSchedule, template: '' });
    }
    if (fieldName === "template") {
      const { isSchedule, isTemplateSchedule } = this.state;
      let name = e.name;
      let searchTerm = "schedule";
      let position = name.toLowerCase().search(searchTerm.toLowerCase());
      if (isSchedule == 'false') {
        if (position !== -1) {
          const isSchedule = 'true';
          this.setState({ isSchedule, isTemplateSchedule: true });
        }
      } else {
        if (isTemplateSchedule == true) {
          if (position !== -1) {
            const isSchedule = 'true';
            this.setState({ isSchedule, isTemplateSchedule: true });
          } else {
            this.setState({ isSchedule: 'false', isTemplateSchedule: false });
          }
        }
      }
      this.getDynamicFields(e.id);
      this.manageStaticFields(e.id)
    }
    let input = fieldValue;
    if (e && e.target && e.target.type === "checkbox") {
      if (fieldName == "is_total_extent_total") {
        if (e.target.checked == false) {
          this.setState({ is_total_extent: "" });
          this.setState({ is_total_extent_total: false });
        } else {
          this.setState({ is_total_extent_total: true });
          this.setState({ is_total_extent_part: false });
          this.setState({ is_total_extent: fieldValue });
        }
      } else if (fieldName == "is_total_extent_part") {
        if (e.target.checked == false) {
          this.setState({ is_total_extent: "" });
          this.setState({ is_total_extent_part: false });
        } else {
          this.setState({ is_total_extent_part: true });
          this.setState({ is_total_extent_total: false });
          this.setState({ is_total_extent: fieldValue });
        }
      } else if (fieldName == "hide") {
        this.setState({ [fieldName]: e.target.checked });
      } else if (fieldName == "joint") {
        this.setState({ [fieldName]: e.target.checked });
      }
      else {
        this.setState({ is_total_extent: "" });
      }
    } else {
      this.setState({ [fieldName]: input });
    }
  };
  getTemplates = deed => {
    callApi("POST", "template_list_bydeed.php", { deed })
      .then(res => res.data)
      .then(response => {
        this.setState({ templates: response.data });
        console.log("templates", this.state.templates);
      })
      .catch(err => {
        console.log(err);
      });
  };
  // handleTemplateSelect = (templateId) => {
  //   this.setState({ selectedTemplate: templateId.isScheduleChecked });
  //   console.log("templateId", templateId);
  // };
  addExecutant = (): void => {
    const main_executant: Executant[] = this.state.main_executant;
    main_executant.push({ executant: "" });
    this.setState({ main_executant });
  };
  removeExecutant = (modal: Executant, index: number): void => {
    const main_executant: Executant[] = this.state.main_executant;
    main_executant.splice(index, 1);

    const delete_items: any = this.state.delete_items;
    delete_items.main_executant.push(modal.executant_id);
    this.setState({ main_executant, delete_items });
  };
  addLegal = (): void => {
    const legalHiersFields: LegalHiers[] = this.state.legalHiersFields;
    legalHiersFields.push({ legal_hiers: "", relationship_dropdown: "", name_of_demise: "" });
    this.setState({ legalHiersFields });
    console.log(legalHiersFields);

  };
  removeLegal = (modal: LegalHiers, index: number): void => {
    const legalHiersFields: LegalHiers[] = this.state.legalHiersFields;
    legalHiersFields.splice(index, 1);
    const delete_items: any = this.state.delete_items;
    delete_items.legalHiersFields.push(modal.legal_hiers_id);
    this.setState({ legalHiersFields, delete_items });
  };
  handlelegalChange = (e: any, index: number, propName?) => {
    const fieldValue = propName == 'relationship_dropdown' ? e.value : e.target.value;
    const fieldName = propName == 'relationship_dropdown' ? 'relationship_dropdown' : e.target.name;
    const legalHiersFields: LegalHiers[] = this.state.legalHiersFields;
    legalHiersFields.forEach((itm: LegalHiers, i: number) => {
      if (i === index) {
        itm[fieldName] = fieldValue;
      }
    });
    this.setState({ legalHiersFields });
    console.log("this.state", this.state.legalHiersFields);
  };
  handleExecutantChange = (e: any, index: number) => {
    const main_executant: Executant[] = this.state.main_executant;
    main_executant.forEach((itm: Executant, i: number) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ main_executant });
  };
  addFavour = (): void => {
    const main_favour: Favour[] = this.state.main_favour;
    main_favour.push({ favour: "" });
    this.setState({ main_favour });
  };
  removeFavour = (modal: Favour, index: number): void => {
    const main_favour: Favour[] = this.state.main_favour;
    main_favour.splice(index, 1);

    const delete_items: any = this.state.delete_items;
    delete_items.main_favour.push(modal.favour_id);
    this.setState({ main_favour, delete_items });
  };
  handleFavourChange = (e: any, index: number) => {
    const main_favour: Favour[] = this.state.main_favour;
    main_favour.forEach((itm: Favour, i: number) => {
      if (i === index) {
        itm[e.target.name] = e.target.value;
      }
    });
    this.setState({ main_favour });
  };
  changeFileType = () => {
    callApi("POST", "file_type.php", {
      id: this.props.match.params.id,
      type: this.state.type
    })
      .then(res => res.data)
      .then(response => {
        if (this.state.type === "title_deed") {
          this.props.history.push(
            "/general-detail/1/" +
            this.props.match.params.fileid +
            "/" +
            this.props.match.params.id
          );
        } else if (this.state.type === "ec_doc") {
          this.props.history.push(
            "/ec-form/" +
            this.props.match.params.fileid +
            "/" +
            this.props.match.params.id +
            "/" +
            this.props.match.params.tabId
          );
        } else {
          window.location.reload();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  stateToProps = event => {
    const state: any = this.state;
    state[event.name] = event.value;
    this.setState({ ...state });
  };
  saveScrutiny = saveStatus => {
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const state: any = this.state;
    state.file_id = this.props.match.params.fileid;
    state.fileid = this.props.match.params.fileid;
    state.fod_fk = this.props.match.params.id;
    if (state.modifySelectToCommas && state.modifySelectToCommas.length) {
      state.modifySelectToCommas.forEach(comma => {
        if (comma !== 'deed') {
          // Ensure state[comma] is an array before performing operations
          state[comma] = Array.isArray(state[comma]) && state[comma].length > 0
            ? state[comma].map(itm => itm && itm.name ? itm.name : '').filter(Boolean).toString()
            : '';
        } else {
          // Ensure state[comma] is an array before performing operations
          state[comma] = Array.isArray(state[comma]) && state[comma].length > 0
            ? state[comma].map(itm => itm && itm.id ? itm.id : '').filter(Boolean).toString()
            : '';
        }
      });
    }

    if (!state.modifySelectToCommas || !state.modifySelectToCommas.length || state.modifySelectToCommas.indexOf('deed') === -1) {
      // Ensure state.deed is an array before performing operations
      state.deed = Array.isArray(state.deed) && state.deed.length > 0
        ? state.deed.map(itm => itm && itm.id ? itm.id : '').filter(Boolean).toString()
        : '';
    }
    state.status = saveStatus;
    this.setState({ required: true });
    // this.setState({ isLoading: true });
    if (this.state.inactive == 1) {
      {
        this.setState({ isLoading: true });
        sessionStorage.removeItem('overviewDocument');
        callApi("POST", "doc_scrutinize.php", state)
          .then(res => res.data)
          .then(response => {
            delete state.base;
            delete state.deeds;
            delete state.originalPresents;
            delete state.location;
            delete state.templates;
            delete state.dynamicFields;
            delete state.categories;
            delete state.registers;
            state.status = saveStatus;
            this.setState({ isLoading: false }, () => {
              if (isVerifyFlow) {
                ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
                setTimeout(() => {
                  window.history.back();
                }, 3000);
              } else this.props.history.push(
                "/work-file/" + this.props.match.params.tabId + "/" + this.props.match.params.fileid
              );
            });

          })
          .catch(err => {
            console.log(err);
            this.setState({ isLoading: false });
          });
      }
    } else {
      const { remark, nature_doc, template, doc_no, service_no, main_executant, org_pres, obtined, staticFilesToHide, current_survey, extent, unit, main_favour, isSchedule } = this.state;
      if (!remark || !nature_doc || !template || !org_pres) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (obtined == "0" || !obtined) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('executant') && !main_executant[0]?.executant) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('doc_no') && !doc_no) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('survey_no') && !current_survey && !isSchedule) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('extent') && !extent && !isSchedule) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('extent') && !unit && !isSchedule) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('sro') && !service_no) {
        this.setState({ required: true });
        ToastsStore.error("Some Required fields are empty");
      } else if (staticFilesToHide && staticFilesToHide.includes('favour') && !isSchedule) {
        if (main_favour && main_favour.length > 0) {
          const isValid: any = this.validateMainFavour();
          if (!isValid) {
            this.setState({ required: true });
            ToastsStore.error("Some Required fields are empty");
            return;
          } else {
            this.docScrutiny(saveStatus);
          }
        } else {
          this.docScrutiny(saveStatus);
        }
      } else {
        this.docScrutiny(saveStatus);
      }
    };
  }
  docScrutiny = (saveStatus) => {
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const state: any = this.state;
    this.setState({ required: true });
    this.setState({ isLoading: true });
    sessionStorage.removeItem('overviewDocument');
    callApi("POST", "doc_scrutinize.php", state)
      .then(res => res.data)
      .then(response => {
        delete state.base;
        delete state.deeds;
        delete state.originalPresents;
        delete state.location;
        delete state.templates;
        delete state.dynamicFields;
        delete state.categories;
        delete state.registers;
        state.status = saveStatus;
        this.setState({ isLoading: false }, () => {
          if (isVerifyFlow) {
            ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
            setTimeout(() => {
              window.history.back();
            }, 3000);
          } else this.props.history.push(
            "/work-file/" + this.props.match.params.tabId + "/" + this.props.match.params.fileid
          );
        });

      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }
  // saveScrutiny1 = saveStatus => {
  //   const HaskKey = this.props.location.hash || "";
  //   const isVerifyFlow = HaskKey.includes("Verify");
  //   const state: any = this.state;
  //   console.log("saveScrutiny______________", state);
  //   state.file_id = this.props.match.params.fileid;
  //   state.fileid = this.props.match.params.fileid;
  //   state.fod_fk = this.props.match.params.id;
  //   if (state.modifySelectToCommas && state.modifySelectToCommas.length) {
  //     state.modifySelectToCommas.forEach(comma => {
  //       if (comma !== 'deed') { // dynamic fields send name in payload
  //         state[comma] = state[comma] && state[comma].length > 0
  //           ? state[comma].map(itm => itm && itm.name ? itm.name : '').filter(Boolean).toString()
  //           : '';
  //       } else {
  //         state[comma] = state[comma] && state[comma].length > 0
  //           ? state[comma].map(itm => itm && itm.id ? itm.id : '').filter(Boolean).toString()
  //           : '';
  //       }
  //     });
  //   }

  //   if (!state.modifySelectToCommas || !state.modifySelectToCommas.length || state.modifySelectToCommas.indexOf('deed') === -1) {
  //     state.deed = state.deed && state.deed.length > 0
  //       ? state.deed.map(itm => itm && itm.id ? itm.id : '').filter(Boolean).toString()
  //       : '';
  //   }

  //   state.status = saveStatus;
  //   this.setState({ required: true });
  //   if (this.state.inactive == 1) {
  //     {
  //       this.setState({ isLoading: true });
  //       sessionStorage.removeItem('overviewDocument');
  //       callApi("POST", "doc_scrutinize.php", state)
  //         .then(res => res.data)
  //         .then(response => {
  //           delete state.base;
  //           delete state.deeds;
  //           delete state.originalPresents;
  //           delete state.location;
  //           delete state.templates;
  //           delete state.dynamicFields;
  //           delete state.categories;
  //           delete state.registers;
  //           state.status = saveStatus;
  //           this.setState({ isLoading: false }, () => {
  //             if (isVerifyFlow) {
  //               ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
  //               setTimeout(() => {
  //                 window.history.back();
  //               }, 3000);
  //             } else this.props.history.push(
  //               "/work-file/" + this.props.match.params.tabId + "/" + this.props.match.params.fileid
  //             );
  //           });

  //         })
  //         .catch(err => {
  //           console.log(err);
  //           this.setState({ isLoading: false });
  //         });
  //     }
  //   } else {
  //     if (!this.state.remark || !this.state.nature_doc || !this.state.template) {
  //       this.setState({ required: true });
  //       ToastsStore.error("Some Required fields are empty");
  //     }
  //     else {
  //       this.setState({ isLoading: true });
  //       sessionStorage.removeItem('overviewDocument');
  //       callApi("POST", "doc_scrutinize.php", state)
  //         .then(res => res.data)
  //         .then(response => {
  //           delete state.base;
  //           delete state.deeds;
  //           delete state.originalPresents;
  //           delete state.location;
  //           delete state.templates;
  //           delete state.dynamicFields;
  //           delete state.categories;
  //           delete state.registers;
  //           state.status = saveStatus;
  //           this.setState({ isLoading: false }, () => {
  //             if (isVerifyFlow) {
  //               ToastsStore.success("Changes saved Successfully.\nRedirecting to Verify Details");
  //               setTimeout(() => {
  //                 window.history.back();
  //               }, 3000);
  //             } else this.props.history.push(
  //               "/work-file/" + this.props.match.params.tabId + "/" + this.props.match.params.fileid
  //             );
  //           });

  //         })
  //         .catch(err => {
  //           console.log(err);
  //           this.setState({ isLoading: false });
  //         });
  //     }
  //   }

  // };
  validateMainFavour = () => {
    let base: any = this.state.main_favour;
    let isValid = true;
    for (let i = 0; i < base.length; i++) {
      const document = base[i];
      if (!document.favour) {
        isValid = false;
        base[i].isValid = false;
      } else {
        base[i].isValid = true;
      }
    }
    return isValid;
  }
  modifyScrutinyStatus = () => {
    callApi("POST", "scrutiny_status.php", {
      id: this.state.scrutiny_id,
      type: "scrutiny",
      status: this.state.approve === "1" ? 0 : 1
    })
      .then(res => res.data)
      .then(response => {
        this.getScrutinyStatus();
      })
      .catch(err => {
        console.log(err);
      });
  };
  onModifyTotalExtent = () => {
    callApi("POST", "scrutiny_total_extent.php", {
      scrutiny_id: this.props.match.params.fileid,
      total_extent: this.state.total_extent,
      extent_unit: this.state.extent_unit
    })
      .then(res => res.data)
      .then(response => {

      })
      .catch(err => {
        console.log(err);
      });
  }
  viewDocument = (id) => {
    this.setState({ isHightlightsLoaded: false });
    callApi("POST", "getbaseby_id.php", { id })
      .then(res => res.data)
      .then(response => {
        if (response.data && response.data.length) {
          const document = this.document;
          document.base = response.data[0].base[0].base;
          const state = this.state;
          // state.type = response.data[0].base[0].type;
          state.selectedDoc = id;
          this.setState({ ...document, state }, () => this.setState({ isHightlightsLoaded: true }));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  onHighlightsChange = (highlights) => {
    this.setState({ highlights })
  }
  updatedRemainingTime(time) {
  }
  togglecheckbox = async (e: any) => {
    const scrutyCheck: any = e.target.checked;
    this.setState({ scrutyCheck });
  };
  render() {
    const staticFilesToHide: any = this.state.staticFilesToHide;
    const { rotation, velocityEqualToMove, enableVelocity, highlights, isHightlightsLoaded, base, docToPreview, loader, timer } = this.state;
    const HaskKey = this.props.location.hash || "";
    const isVerifyFlow = HaskKey.includes("Verify");
    const takenfile_id: any = sessionStorage.getItem("takenfile_id");
    const currentfile_Id: any = sessionStorage.getItem("currentfileId");
    const VerificationToAddon: any = localStorage.getItem("verification_to_addon");
    let takenfile = null;
    if (takenfile_id) {
      try {
        takenfile = JSON.parse(takenfile_id);
      } catch (error) {
        console.error("Error parsing takenfile_id JSON:", error);
      }
    };
    const currentfile = JSON.parse(currentfile_Id);
    const user: any = sessionStorage.getItem("user");
    const roletype = user ? JSON.parse(user)["roleType"] : "";
    let initialTimeInMinutes: any = sessionStorage.getItem('updatedRemainingTime');
    return (
      <div className="pcoded-content">
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div>
                <div>
                  <div className="card" style={{ marginBottom: '0px' }}>
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-12">
                          {initialTimeInMinutes && initialTimeInMinutes > 0 && (
                            <CountdownTimer
                              initialTime={initialTimeInMinutes}
                              updatedTime={(time) => this.updatedRemainingTime(time)}
                              ref={(ref) => (this.countdownTimerRef = ref)}
                            />
                          )}
                        </div><br />
                      </div>
                      <h5>Document</h5>
                    </div>
                    <div className="card-body">
                      <div className="inline-block">
                        <Resizable id="frame" className="box" height={this.state.documentHeight} width={this.state.documentWidth} onResize={this.onDocumentResize} resizeHandles={['e']}>
                          <div
                            // className="col-md-8"
                            style={{
                              overflow: "auto", marginRight: '0', maxWidth: '60%', minWidth: '40%', float: 'left', width: this.state.documentWidth + '%'
                            }}
                          >
                            <div className="container" style={{
                              border: "1px solid #e3e5e8",
                              borderRadius: "10px"
                            }}>
                              <div className="row" style={{ width: "100%", display: "flex", paddingTop: "8px" }}>
                                {this.state.allDocuments && this.state.allDocuments.length > 0 &&
                                  <div className="col-md-6" style={{ minWidth: "40%" }}>
                                    <label>Document</label>
                                    {this.state.allDocuments && this.state.allDocuments.length > 0 && this.state.allDocuments.map((opt: any) => {
                                      // opt.label = 'Document - ' + opt.id + (opt.status == 1 ? "- (Not Related)" : "");
                                      opt.label = opt.id + (opt.status == 1 ? "- (Not Related)" : "");
                                      opt.value = opt.base_id
                                    }) &&
                                      <Select options={this.state.allDocuments} name="selectedDoc" style={{
                                        height: "2.1em",
                                        marginTop: "-2px"
                                      }}
                                        value={this.state.allDocuments.find((o: any) => o.value === this.state.selectedDoc)}
                                        onChange={(e) => this.viewDocument(e.value)}
                                      />
                                    }
                                  </div>}
                                <div className="col-md-2" style={{ marginTop: "25px" }}>
                                  <button
                                    type="button"
                                    style={{ float: "right" }}
                                    className="btn btn-warning"
                                    onClick={this.getRevenueDocs}
                                  >
                                    Original
                                  </button>
                                </div>
                                <div className="col-md-4" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                  <label>Not Related Doc</label>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <label style={{ margin: '0px 10px' }}>No</label>
                                    <label className="switch" style={{ marginBottom: "0px" }}>
                                      <input type="checkbox" checked={this.state.inactive === 1 ? true : false} onChange={this.toggleDocStatus} />
                                      <span className="slider round"></span>
                                    </label>
                                    <label style={{ margin: '0px 10px' }}>Yes</label>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                  <a className="nav-link active" id="old-view-tab" data-toggle="tab"
                                    href="#old-viewer" role="tab" aria-controls="home" aria-selected="true">
                                    Old Viewer
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" id="new-view-tab" data-toggle="tab"
                                    href="#new-viewer" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.setState({ docToPreview: true })}>
                                    New Viewer
                                  </a>
                                </li>
                              </ul>
                              {loader ? (
                                <>
                                  <Spinner />
                                  <div className="text-center">
                                    <h4 style={{ marginTop: '49%', marginLeft: '90px' }}>
                                      <span >Please wait...{timer}</span>
                                    </h4>
                                  </div>
                                </>

                              ) : (
                                <div className="tab-content" style={{
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px"
                                }} id="tab-content">
                                  <div className="tab-pane fade show active" id="old-viewer" role="tabpanel" aria-labelledby="old-viewer">
                                    <iframe
                                      src={this.document.base}
                                      width='100%'
                                      height={this.state.documentHeight}
                                    />
                                  </div>
                                  <div className="tab-pane fade" id="new-viewer" role="tabpanel" aria-labelledby="new-viewer">
                                    <p style={{ color: 'red' }}>NOTE: TO HIGHLIGHT [ ALT + RIGHT CLICK ON MOUSE AND DRAG ]</p>
                                    <br />
                                    <div className="App" style={{ display: 'flex', height: '550px' }}>
                                      <TransformWrapper
                                        pan={{
                                          disabled: true,
                                          velocityEqualToMove,
                                          velocity: enableVelocity
                                        }}
                                        wheel={{
                                          disabled: true
                                        }}
                                      >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                          <React.Fragment>
                                            <div className="tools">
                                              <Rotate90DegreesCcwIcon className="pdf-icon rotate-icon" onClick={this.rotate} />
                                              <ZoomInIcon className="pdf-icon" onClick={zoomIn} />
                                              <ZoomOutIcon className="pdf-icon" onClick={zoomOut} />
                                              <FullscreenExitIcon className="pdf-icon" onClick={resetTransform} />
                                              {base && isHightlightsLoaded && <FullscreenIcon className="pdf-icon" onClick={() => this.setState({ docToPreview: true })} />}
                                            </div>
                                            <TransformComponent>
                                              <div
                                                style={{
                                                  height: '550px',
                                                  width: '45vw',
                                                  position: 'relative',
                                                  transform: `rotate(${rotation}deg)`
                                                }}
                                              >
                                                {base && !docToPreview && <PDFHighlighter url={base} onHighlightsChange={this.onHighlightsChange} highlights={highlights} />}
                                              </div>
                                            </TransformComponent>
                                          </React.Fragment>
                                        )}
                                      </TransformWrapper>
                                    </div>
                                  </div>
                                </div>
                              )};
                            </div>
                          </div>
                        </Resizable>

                        <Resizable className="box" height={this.state.formHeight} width={this.state.formWidth} onResize={this.onFormResize} resizeHandles={[]}>
                          <div style={{ width: this.state.formWidth + '%', height: this.state.formHeight + 'px', float: 'right' }}>
                            <div className="row form-document-scrutiny">
                              <div className="col-md-12">
                                {/* <div className="row">
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Total Extent</label>
                                      <input
                                        type="text"
                                        // className={this.state.required && this.state.total_extent === '' ? 'form-control is-invalid' : 'form-control'}
                                        className="form-control"
                                        placeholder="Total Extent"
                                        name="total_extent"
                                        value={this.state.total_extent}
                                        onChange={e => this.handleChange(e)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <label>Select Unit <span className="inputEgMsg">Eg.434 Sq.Meter / Sq.Yards Etc...</span></label>
                                      {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                        <Select options={this.state.extentUnits} name="extent_unit"
                                          value={this.state.extentUnits.find((o: any) => o.value === this.state.extent_unit)}
                                          onChange={(e) => this.handleChange(e, "extent_unit", 'singleselect')}
                                        />
                                      }
                                    </div>
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{
                                      margin: "22px"
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary sw-btn-next"
                                      type="button"
                                      onClick={() => this.onModifyTotalExtent()}
                                    >
                                      Modify
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-9">
                                    <label>Category <span className="inputEgMsg">Eg. Revenue Record / Deed / Mother Document / Title Deed / EC / Sale Agreement</span></label>
                                    {this.state.categories && this.state.categories.length > 0 && this.state.categories.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                      <Select options={this.state.categories} name="type"
                                        value={this.state.categories.find((o: any) => o.value === this.state.type)}
                                        onChange={(e) => this.handleChange(e, "type", 'singleselect')}
                                      />
                                    }
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{ margin: "23px" }}
                                  >
                                    <button
                                      className="btn btn-primary sw-btn-next"
                                      type="button"
                                      onClick={() => this.changeFileType()}
                                    >
                                      Confirm
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Document Type<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Eg. Sale Deed / Will Deed Etc...</span></label>
                                  {this.state.deeds && this.state.deeds.length > 0 && this.state.deeds.map((opt: any) => {
                                    opt.label = opt.name;
                                    opt.value = opt.id;
                                  }) &&
                                    <Select options={this.state.deeds} name="nature_doc"
                                      className={this.state.required && isEmpty(this.state.nature_doc) ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.deeds.find((o: any) => o.value === this.state.nature_doc)}
                                      onChange={(e) => this.handleChange(e, "nature_doc", 'singleselect')}
                                    />
                                  }
                                  <div className="invalid-feedback">This is a required field, kindly add Document Type here.</div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Template<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Tracing of History</span></label>
                                  {this.state.templates && this.state.templates.length > 0 && this.state.templates.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.templates} name="template"
                                      className={this.state.required && isEmpty(this.state.template) ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.templates.find((o: any) => o.value === this.state.template)}
                                      onChange={(e) => {
                                        this.handleChange(e, "template", 'singleselect');
                                        //this.handleTemplateSelect(e);
                                      }}
                                    />
                                  }
                                  <div className="invalid-feedback">This is a required field, kindly Template here.</div>
                                </div>
                              </div>
                              {staticFilesToHide.includes('reg') && <div className="col-md-6">
                                <div className="form-group">
                                  <label>Regd/Unregd</label>
                                  {this.state.registers && this.state.registers.length > 0 && this.state.registers.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.registers} name="reg"
                                      value={this.state.registers.find((o: any) => o.value === this.state.reg)}
                                      onChange={(e) => this.handleChange(e, "reg", 'singleselect')}
                                    />
                                  }
                                </div>
                              </div>
                              }

                              {/* <h2>Custom Fields</h2> */}
                              {this.state.dynamicFields &&
                                this.state.dynamicFields.length
                                ? <div className="col-md-12 highlight-border" style={{ borderRadius: "10px", padding: "10px" }}>
                                  <div className="row">
                                    {this.state.dynamicFields.map(
                                      (itm: any, index) => {
                                        return (
                                          <div className="col-md-6" key={index}>
                                            <div className="form-group">
                                              <label>{itm.label}</label>
                                              {itm.type === 'select' ? itm.optionList && itm.optionList.length && itm.optionList.map((opt: any) => { opt.label = opt.name; opt.value = opt.name }) &&
                                                (
                                                  <Select options={itm.optionList} name={itm.name}
                                                    value={this.state[itm.name]} isMulti={true}
                                                    onChange={(e) => this.handleChange(e, itm.name, 'multiselect')}
                                                  />
                                                ) :
                                                itm.type === "textarea" ? (
                                                  <textarea
                                                    className="form-control"
                                                    name={itm.name}
                                                    value={this.state[itm.name]}
                                                    onChange={e =>
                                                      this.handleChange(e)
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    type={itm.type}
                                                    className="form-control"
                                                    placeholder={itm.label}
                                                    name={itm.name}
                                                    value={this.state[itm.name]}
                                                    checked={this.state[itm.name]}
                                                    onChange={e => this.handleChange(e, itm.name, 'checkbox')}
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div className="row mt-3" style={{ justifyContent: "end" }}>
                                    {this.state.legalHiers == true && this.state.legalHiersFields &&
                                      <div style={{
                                        // marginTop: "20px",
                                        // float: "right",
                                        // width: "80%"
                                      }}>
                                        <button
                                          type="button"
                                          onClick={this.addLegal}
                                          className="btn  btn-primary"
                                        >
                                          <i className="feather icon-plus" /> Add LegalHiers
                                        </button>
                                      </div>
                                    }
                                  </div>
                                  <div className="mt-3">
                                    {this.state.legalHiers == true && this.state.legalHiersFields ? this.state.legalHiersFields.map((itm: any, index: any) => {
                                      return (
                                        <div className="row" key={index}>
                                          <div className="col-md-4" >
                                            <div className="form-group">
                                              <label>Legal Heirs</label>
                                              <textarea
                                                className="form-control"
                                                name="legal_hiers"
                                                value={itm.legal_hiers}
                                                onChange={e =>
                                                  this.handlelegalChange(e, index)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <label>Relationship</label>
                                              {this.state.optionList && this.state.optionList.length && this.state.optionList.map((opt: any) => { opt.label = opt.name; opt.value = opt.name }) &&
                                                (
                                                  <Select options={this.state.optionList} name="relationship_dropdown"
                                                    value={this.state.optionList.find((o: any) => o.value === itm.relationship_dropdown)} isMulti={false}
                                                    onChange={(e) => this.handlelegalChange(e, index, 'relationship_dropdown')}
                                                  />
                                                )}
                                            </div>
                                          </div>
                                          <div className="col-md-3" >
                                            <div className="form-group">
                                              <label>Name of Demise</label>
                                              <textarea
                                                className="form-control"
                                                name="name_of_demise"
                                                value={itm.name_of_demise}
                                                onChange={e =>
                                                  this.handlelegalChange(e, index)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-1">
                                            <div style={{
                                              marginTop: "25px",
                                              float: "right",
                                              // width: "80%"
                                            }}>
                                              {/* <button
                                              type="button"
                                              onClick={this.addLegal}
                                              className="btn btn-icon btn-rounded btn-primary"
                                            >
                                              <i className="feather icon-plus" />
                                            </button> */}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.removeLegal(
                                                    itm,
                                                    index
                                                  )
                                                }
                                                disabled={this.state.legalHiersFields.length === 1}
                                                className="btn btn-icon btn-rounded btn-danger"
                                              >
                                                <i className="feather icon-minus" />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }) : ""}
                                  </div>
                                </div> : ""}
                              {this.state.isSchedule === "true" && (
                                <ScrutinyScheduleItem
                                  stateToProps={this.stateToProps}
                                  scheduleDeed={this.state.schedule_deed}
                                //selectedTemplate={this.state.selectedTemplate}
                                //templates={this.state.templates} 
                                />
                              )}
                              {/* {staticFilesToHide.includes('scheduled_relationship') && (
                                <ScrutinyScheduleItem
                                  stateToProps={this.stateToProps}
                                  scheduleDeed={this.state.schedule_deed}
                                  selectedTemplate={this.state.selectedTemplate}
                                  templates={this.state.templates} 
                                /> 
                              )} */}
                              {(this.state.isIssue === "true" || staticFilesToHide.includes('issued_by')) && (
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Issued By</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Eg. VAO / Tahsildhir"
                                      name="issued_by"
                                      value={this.state.issued_by}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Date</label>
                                  <MaskedInput
                                    className="form-control"
                                    mask={[
                                      /[0-3]/,
                                      /\d/,
                                      ".",
                                      /[0-1]/,
                                      /\d/,
                                      ".",
                                      /[1-2]/,
                                      /\d/,
                                      /\d/,
                                      /\d/
                                    ]}
                                    placeholder="dd.mm.yyyy"
                                    value={this.state.date}
                                    onChange={e => this.handleChange(e)}
                                    name="date"
                                  />
                                </div>
                              </div>
                              {this.state.isSchedule !== "true" && staticFilesToHide.includes('favour') && (
                                <div className="col-md-12">
                                  {this.state.main_favour &&
                                    this.state.main_favour.length
                                    ? this.state.main_favour.map(
                                      (fav: Favour, index) => {
                                        return (
                                          <div
                                            className="form-group"
                                            key={index}
                                          >
                                            <label>Favour/Claimant<span style={{ color: "#FF0000" }}>*</span></label>
                                            <div className="row">
                                              <div className="col-md-9">
                                                <input
                                                  type="text"
                                                  className={this.state.required && isEmpty(fav.favour) ? 'form-control is-invalid' : 'form-control'}
                                                  placeholder="Favour"
                                                  name="favour"
                                                  value={fav.favour}
                                                  onChange={e =>
                                                    this.handleFavourChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                                <div className="invalid-feedback">This is a required field, kindly add Favour here.</div>
                                              </div>
                                              <div className="col-md-3">
                                                <div style={{
                                                  marginTop: "-17px !important",
                                                  float: "right",
                                                  margin: "-18px",
                                                  width: "80%"
                                                }}>
                                                  <button
                                                    onClick={this.addFavour}
                                                    style={{ margin: "17px" }}
                                                    type="button"
                                                    className="btn btn-icon btn-rounded btn-primary"
                                                  >
                                                    <i className="feather icon-plus" />
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      this.removeFavour(
                                                        fav,
                                                        index
                                                      )
                                                    }
                                                    type="button"
                                                    className="btn btn-icon btn-rounded btn-danger"
                                                  >
                                                    <i className="feather icon-minus" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                    : ""}
                                </div>
                              )}
                              {staticFilesToHide.includes('executant') && (<div className="col-md-12">
                                {this.state.main_executant
                                  ? this.state.main_executant.map(
                                    (exec: Executant, index) => {
                                      return (
                                        <div className="form-group" key={index}>
                                          <label>Executant<span style={{ color: "#FF0000" }}>*</span></label>
                                          <div className="row">
                                            <div className="col-md-9">
                                              <input
                                                type="text"
                                                className={this.state.required && isEmpty(exec.executant) ? 'form-control is-invalid' : 'form-control'}
                                                placeholder="Executant"
                                                name="executant"
                                                value={exec.executant}
                                                onChange={e =>
                                                  this.handleExecutantChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                              <div className="invalid-feedback">This is a required field, kindly add Executant here.</div>
                                            </div>
                                            <div className="col-md-3">
                                              <div style={{
                                                marginTop: "-17px !important",
                                                float: "right",
                                                margin: "-18px",
                                                width: "80%"
                                              }}>
                                                <button
                                                  type="button"
                                                  style={{ margin: "17px" }}
                                                  onClick={this.addExecutant}
                                                  className="btn btn-icon btn-rounded btn-primary"
                                                >
                                                  <i className="feather icon-plus" />
                                                </button>
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    this.removeExecutant(
                                                      exec,
                                                      index
                                                    )
                                                  }
                                                  className="btn btn-icon btn-rounded btn-danger"
                                                >
                                                  <i className="feather icon-minus" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                  : ""}
                              </div>)}

                              {staticFilesToHide.includes('doc_no') && <div className="col-md-6">
                                <div className="form-group">
                                  <label>Document No/Year<span style={{ color: "#FF0000" }}>*</span></label>
                                  <input
                                    type="text"
                                    className={this.state.required && isEmpty(this.state.doc_no) ? 'form-control is-invalid' : 'form-control'}
                                    placeholder="Eg. 241/2019"
                                    name="doc_no"
                                    value={this.state.doc_no}
                                    onChange={e => this.handleChange(e)}
                                  />
                                  <div className="invalid-feedback">This is a required field, kindly add Document No/Year here.</div>
                                </div>
                              </div>
                              }

                              {staticFilesToHide.includes('executant') && <div className="col-md-6">
                                <div className="form-group">
                                  <label>Mother Document No/Year</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. 190/2010"
                                    name="mother_doc"
                                    value={this.state.mother_doc}
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              }
                              {staticFilesToHide.includes('executant') && <div className="col-md-12">
                                <div className="form-group">
                                  <label>Mother Document Deed <span className="inputEgMsg">Eg. Sale / Partition / Gift Etc..</span></label>
                                  {this.state.deeds && this.state.deeds.length > 0 && this.state.deeds.map((opt: any) => { opt.label = opt.name; opt.value = opt.id })}
                                  {/* to get compatible options for react-select */}
                                  <Select options={this.state.deeds} name="deed"
                                    value={this.state.deed} isMulti
                                    onChange={(e) => this.handleChange(e, 'deed', 'multiselect')}
                                  />
                                </div>
                              </div>
                              }

                              {staticFilesToHide.includes('survey_no') && <div className="col-md-6">
                                <div className="form-group">
                                  <label>Old Survey No</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. 165/1D"
                                    name="old_surevey"
                                    value={this.state.old_surevey}
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              }
                              {this.state.isSchedule !== "true" && staticFilesToHide.includes('survey_no') && (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Current Survey No<span style={{ color: "#FF0000" }}>*</span></label>
                                    <input
                                      type="text"
                                      className={this.state.required && isEmpty(this.state.current_survey) ? 'form-control is-invalid' : 'form-control'}
                                      placeholder="Eg. 165/2A, 165/1E, 181/1A"
                                      name="current_survey"
                                      value={this.state.current_survey}
                                      onChange={e => this.handleChange(e)}
                                    />
                                    <div className="invalid-feedback">This is a required field, kindly add Current Survey No here.</div>
                                  </div>
                                </div>
                              )}

                              {this.state.isSchedule !== "true" && staticFilesToHide.includes('extent') && (
                                <>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Extent<span style={{ color: "#FF0000" }}>*</span></label>
                                      <input
                                        type="text"
                                        className={this.state.required && isEmpty(this.state.extent) ? 'form-control is-invalid' : 'form-control'}
                                        placeholder="Eg. 1500 / 0.52"
                                        name="extent"
                                        value={this.state.extent}
                                        onChange={e => this.handleChange(e)}
                                      />
                                      <div className="invalid-feedback">This is a required field, kindly add Extent here.</div>
                                    </div>
                                  </div>
                                  {staticFilesToHide.includes('extent') && <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Select Unit<span style={{ color: "#FF0000" }}>*</span></label>
                                      {this.state.extentUnits && this.state.extentUnits.length > 0 && this.state.extentUnits.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                        <Select options={this.state.extentUnits} name="unit"
                                          className={this.state.required && isEmpty(this.state.unit) ? 'form-select is-invalid' : 'form-select'}
                                          value={this.state.extentUnits.find((o: any) => o.value === this.state.unit)}
                                          onChange={(e) => this.handleChange(e, "unit", 'singleselect')}
                                        />
                                      }
                                      <div className="invalid-feedback">This is a required field, kindly add Extent Unit here.</div>
                                    </div>
                                  </div>
                                  }
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Extent</label>
                                      <br />
                                      <div style={{
                                        borderRadius: "10px",
                                        border: "2px solid",
                                        padding: "6px"
                                      }}>
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="checkbox" name="is_total_extent_total"
                                            value="total" checked={this.state.is_total_extent_total === true} onChange={e => this.handleChange(e)} />
                                          <label className="form-check-label">Total</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="checkbox" name="is_total_extent_part"
                                            value="part" checked={this.state.is_total_extent_part === true} onChange={e => this.handleChange(e)} />
                                          <label className="form-check-label">Part</label>
                                        </div>
                                        {/* <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="is_total_extent"
                                          value="to" checked={this.state.is_total_extent === 'to'} onChange={e => this.handleChange(e)} />
                                        <label className="form-check-label">To</label>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {staticFilesToHide.includes('sro') && <div className="col-md-6">
                                <div className="form-group">
                                  <label>SRO<span style={{ color: "#FF0000" }}>*</span></label>
                                  <button
                                    type="button"
                                    style={{ float: "right", backgroundColor: '#007bff', color: "#fff" }}
                                    data-toggle="modal"
                                    data-target="#sro-form"
                                    onClick={this.addSro}
                                  >
                                    <i className="feather icon-plus" />
                                  </button>
                                  {this.state.sroList && this.state.sroList.length > 0 && this.state.sroList.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.sroList} name="service_no"
                                      className={this.state.required && isEmpty(this.state.service_no) ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.sroList.find((o: any) => o.value === this.state.service_no)}
                                      onChange={(e) => this.handleChange(e, "service_no", 'singleselect')}
                                    />
                                  }
                                  <div className="invalid-feedback">This is a required field, kindly add SRO here.</div>
                                </div>
                              </div>
                              }
                              <div className="col-md-6">
                                <div className="form-group">
                                  {/* Original Present */}
                                  <label>Now Scrutinized<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">Current Document Submitted</span></label>
                                  {this.state.originalPresents && this.state.originalPresents.length > 0 && this.state.originalPresents.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.originalPresents} name="org_pres"
                                      className={this.state.required && isEmpty(this.state.org_pres) ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.originalPresents.find((o: any) => o.value === this.state.org_pres)}
                                      onChange={(e) => this.handleChange(e, "org_pres", 'singleselect')}
                                    />
                                  }
                                  <div className="invalid-feedback">This is a required field, kindly add Now Scrutinized here.</div>
                                </div>
                              </div>
                              {/* <div className="col-md-12"> */}
                              <div className="col-md-6">
                                <div className="form-group ">
                                  {/* Original Present */}
                                  <label>To Be Obtained<span style={{ color: "#FF0000" }}>*</span> <span className="inputEgMsg">From Bank at the Time of Mortgage</span></label>
                                  {this.state.originalPresents && this.state.originalPresents.length > 0 && this.state.originalPresents.map((opt: any) => { opt.label = opt.name; opt.value = opt.id }) &&
                                    <Select options={this.state.originalPresents} name="obtined"
                                      className={this.state.required && isEmpty(this.state.obtined) || this.state.obtined == '0' ? 'form-select is-invalid' : 'form-select'}
                                      value={this.state.originalPresents.find((o: any) => o.value === this.state.obtined)}
                                      onChange={(e) => this.handleChange(e, "obtined", 'singleselect')}
                                    />
                                  }
                                  <div className="invalid-feedback">This is a required field, kindly add To Be Obtained here.</div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Available Sheet Count</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Eg. 20"
                                    name="sheetCount"
                                    min={0}
                                    value={this.state.sheetCount}
                                    onChange={e => this.handleChange(e)}
                                  />
                                </div>
                              </div>
                              {VerificationToAddon == "true" && <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Total Sheet Count</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Eg. 30"
                                      name="totalSheets"
                                      min={0}
                                      value={this.state.totalSheets}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Missing Sheet Count</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Eg. 10"
                                      name="missingSheets"
                                      min={0}
                                      value={this.state.missingSheets}
                                      onChange={e => this.handleChange(e)}
                                    />
                                  </div>
                                </div>
                              </>}
                              {/* </div> */}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Remark<span style={{ color: "#FF0000" }}>*</span></label>
                                  <textarea
                                    className={this.state.required && isEmpty(this.state.remark) ? 'form-control is-invalid' : 'form-control'}
                                    name="remark"
                                    id="remark"
                                    placeholder="Remark"
                                    value={this.state.remark}
                                    onChange={e => this.handleChange(e)}
                                  />
                                  <div className="invalid-feedback">This is a required field, kindly add Remark here.</div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div style={{ float: "inline-start" }}>
                                    <input
                                      style={{ margin: '5px 20px 5px 0px', fontSize: "0px" }}
                                      className="form-control cursor-pointer"
                                      name="normalMode"
                                      type="checkbox"
                                      checked={this.state.scrutyCheck}
                                      onChange={this.togglecheckbox}
                                    />
                                  </div>
                                  <h5>Note / Tracing</h5>
                                  <span className="inputEgMsg">
                                    -If the checkbox is selected, the software generated tracing was disappearing. And displays below entered paragraph Information.<br />
                                    -If the checkbox is not selected, it will work by tracing notes.
                                  </span>
                                  <textarea
                                    name="note"
                                    onChange={e => this.handleChange(e)}
                                    className="form-control"
                                    value={this.state.note}
                                    placeholder="It will display in history of tracing between paragraph"
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{
                              display: "flex",
                              float: "right"
                            }}>
                              <button
                                className="btn btn-warning"
                                type="button"
                                onClick={() => {
                                  isVerifyFlow ? window.history.back() : this.props.history.push("/work-file/" + this.props.match.params.tabId + "/" + this.props.match.params.fileid);
                                }}
                              >
                                Back
                              </button>
                              {this.state.autoAssign && this.state.autoAssign.find(
                                (data: any) => data.module_name === "Auto Assign" && data.status) ?
                                <>
                                  {takenfile && currentfile && takenfile === currentfile ? (
                                    <>
                                      {/* {!isVerifyFlow ? */}
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        style={{ marginTop: "auto" }}
                                        onClick={() => this.saveScrutiny(1)}
                                        disabled={this.state.isLoading}>
                                        {this.state.isLoading && (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        )}
                                        Save
                                      </button>
                                      {/* : null} */}
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => this.saveScrutiny(2)}
                                        disabled={this.state.isLoading}>
                                        {this.state.isLoading && (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        )}
                                        Save & Submit
                                      </button>
                                    </>
                                  ) :
                                    <>
                                      {roletype == 8 ? <>
                                        {!isVerifyFlow ?
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{ marginTop: "auto" }}
                                            onClick={() => this.saveScrutiny(1)}
                                            disabled={this.state.isLoading}>
                                            {this.state.isLoading && (
                                              <i className="fa fa-spinner fa-spin"></i>
                                            )}
                                            Save
                                          </button>
                                          : null}
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                          onClick={() => this.saveScrutiny(2)}
                                          disabled={this.state.isLoading}>
                                          {this.state.isLoading && (
                                            <i className="fa fa-spinner fa-spin"></i>
                                          )}
                                          Save & Submit
                                        </button>
                                      </> : ""}
                                    </>}
                                </> :
                                <>
                                  {!isVerifyFlow ?
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      style={{ marginTop: "auto" }}
                                      onClick={() => this.saveScrutiny(1)}
                                      disabled={this.state.isLoading}>
                                      {this.state.isLoading && (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      )}
                                      Save
                                    </button>
                                    : null}
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={() => this.saveScrutiny(2)}
                                    disabled={this.state.isLoading}>
                                    {this.state.isLoading && (
                                      <i className="fa fa-spinner fa-spin"></i>
                                    )}
                                    Save & Submit
                                  </button>
                                </>}
                            </div>
                          </div>
                        </Resizable>
                      </div>
                      {this.state.temp ? (
                        <div className="add_txtarea">
                          <div className="textbox">
                            <h4>Report Paragraph</h4>
                            <p
                              style={{
                                textAlign: "justify",
                                fontSize: "16px",
                                width: "97%",
                                fontWeight: 500
                              }}
                            >
                              {this.state.temp}
                            </p>
                            {/* <textarea
                              name="temp"
                              disabled={true}
                              className="form-control"
                              value={this.state.temp}
                              placeholder="Address"
                              rows="4"
                            /> */}
                          </div>
                          <div className="save_btn">
                            <button
                              type="submit"
                              className={
                                this.state.approve === "1"
                                  ? "btn btn-danger"
                                  : "btn btn-success"
                              }
                              onClick={this.modifyScrutinyStatus}
                            >
                              {this.state.approve === "1"
                                ? "Pending"
                                : "Approve"}
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddSro
          selectedSro={this.state.selectedSro}
          listSro={this.getSroList}
        />
        <div
          className={`modal ${this.state.docToPreview ? 'open' : ''}`}
          id="preview-document"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document" style={{ maxWidth: '90%' }}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ docToPreview: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ transform: `rotate(${this.state.rotation}deg)` }}>
                {this.state.base && this.state.isHightlightsLoaded && <PDFHighlighter url={this.state.base} onHighlightsChange={this.onHighlightsChange} highlights={this.state.highlights} rotation={this.state.rotation} isPopup />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}